<template>
  <b-card>
    <h5>Progress: almost finished ...</h5>
    <b-progress
      :value="step"
      :max="numberOfSteps"
      class="mb-3"
    />

    <transition name="slide-fade">
      <section v-show="step === 1">
        <b-card title="Your Profile">
          <h5 class="text-muted mb-2">
            Your application has been submitted. You may wish to personalise your profile by adding a picture
          </h5>
          <b-row>
            <b-col
              sm="6"
            >
              <b-form-group
                label-for="photo-input"
              >
                <div class="photo-wrapper">
                  <fileUpload
                    v-model="show"
                    lang-type="en"
                    field="profile_img"
                    :width="80"
                    :height="80"
                    img-format="png"
                    :lang-ext="Object.assign({}, cropperSettings)"
                    @srcFileSet="cropSuccess"
                    @crop-success="cropSuccess"
                  />
                  <b-media>
                    <template #aside>
                      <b-avatar
                        v-if="!imgDataUrl"
                        variant="primary"
                        :text="userInitials"
                        :src="userProfileImage"
                        size="8rem"
                      />
                      <img
                        v-else
                        :src="imgDataUrl"
                        alt="profile_image"
                        style="width: 8rem"
                      >
                    </template>
                    <div class="media-body mt-75 ml-75">
                      <b-button
                        class="mr-2"
                        variant="primary"
                        @click="showCropperDialog"
                      >
                        <feather-icon
                          icon="UploadIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Upload</span>
                      </b-button>
                      <p class="card-text">
                        <small>Allowed JPG, GIF, BMP or PNG. Max size of 5MB</small>
                      </p>
                    </div>
                  </b-media>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col sm="2">
              <b-button
                block
                variant="outline-primary"
                @click="next()"
              >
                Skip Picture
              </b-button>
            </b-col>
            <b-col
              sm="3"
              class="d-flex justify-content-end"
            >
              <b-button
                block
                variant="primary"
                :disabled="!hasProfileImage"
                @click="updateUser()"
              >
                <b-spinner
                  v-if="isSubmitting"
                  small
                />
                Submit Picture
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </section>
    </transition>
    <transition name="slide-fade">
      <section v-show="step === 2">
        <validation-observer
          ref="observer"
          v-slot="{ pristine, invalid, handleSubmit }"
        >
          <b-card title="Connect with Microsoft teams">
            <h5 class="text-muted mb-2">
              Enter your Microsoft Teams details to make it easy to communicate with your connections via this platform.
            </h5>
            <b-row>
              <b-col
                v-if="isTeamsEnabled"
                sm="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  ref="teams"
                  :rules="{ required: true, email: true }"
                  name="Teams"
                >
                  <b-form-group
                    :label="$t('Teams')"
                    label-for="teams"
                  >
                    <template slot="label">
                      Teams
                      <feather-icon
                        v-b-tooltip.hover.top="'Teams Email'"
                        icon="AlertCircleIcon"
                        class="mr-50 my-icon"
                      />
                    </template>
                    <b-form-input
                      v-model="userEdit.teams"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Teams')"
                      name="teams"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
          <b-row class="mt-3">
            <b-col sm="2">
              <b-button
                block
                variant="outline-primary"
                @click="skipToDashboard()"
              >
                Skip
              </b-button>
            </b-col>
            <b-col
              sm="3"
              class="d-flex justify-content-end"
            >
              <b-button
                block
                variant="primary"
                @click="handleSubmit(updateUser)"
              >
                <b-spinner
                  v-if="isSubmitting"
                  small
                />
                Submit
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </section>
    </transition>
  </b-card>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getValidationState } from "@/libs/utils";
import { authService } from "@services";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import {
  integrations as integrationPlatforms
} from "@/models";
import {
  BRow,
  BCol,
  BButton,
  BCard, 
  BMedia,
  BAvatar,
  BFormGroup,
  BFormInput,
  BProgress,
  VBTooltip, 
  BSpinner
} from "bootstrap-vue";
import fileUpload from "vue-image-crop-upload/upload-2.vue";
//eslint-disable-next-line
import { required, email } from "@validations";

export default {
  name: "ApplicationCompleteProfile",
  components:{
    BCard, 
    BProgress,
    ValidationProvider,
    BMedia,
    BAvatar,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    fileUpload,
    BFormInput,
    ValidationObserver,
    BSpinner
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data: () => {
    return {
      steps: {},
      step: 1,
      isSubmitting: false,
      userEdit: {},
      initialEditing: {},
      languageList: [],
      isFetching: false,
      cropperSettings: {
        preview: "Preview",
        btn: {
          off: "Cancel",
          close: "Close",
          back: "Back",
          save: "Save",
        },
        error: {
          onlyImg:
            "Please make sure your file is JPG, GIF, BMP or PNG and less than 5mb.",
          outOfSize:
            "Please make sure your file is JPG, GIF, BMP or PNG and less than ",
        },
      },
      show: false,
      imgDataUrl: "",
      isLoading: false
    };
  },
  computed: {
    userInitials() {
      const firstInt = Array.from(this.user?.first_name)[0];
      const lastInt = Array.from(this.user?.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    user() {
      return this.$store.getters["profile/profile"];
    },
    userProfileImage() {
      return this.user?.profile_image?.url || "";
    },
    hasProfileImage () {
      return Boolean(this.userEdit?.profile_image?.data_uri) || Boolean(this.userProfileImage);
    },
    isTeamsEnabled () {
      if (!this.program || !this.program.integrations) { return false; }
      return this.program.integrations.find(i => i.id === integrationPlatforms.TEAMS)?.is_enabled;
    },
    program() {
      return this.$store.getters["participants/program"];
    },
    numberOfSteps() {
      return this.isTeamsEnabled ? 2 : 1;
    }
  },
  methods: {
    prev() {
      this.step--;
    },
    next() {
      if(this.numberOfSteps === this.step) {
        return this.skipToDashboard();
      }
      this.step++;
    },
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      this.userEdit = {
        profile_image: {
          data_uri: imgDataUrl
        }
      };
    },
    showCropperDialog() {
      this.show = !this.show;
    },
    skipToDashboard() {
      this.$router.push({name: 'participant-home'});
    },
    async updateUser() {
      try {
        this.isSubmitting = true;
        await authService.updateUserProfile(this.userEdit);
        this.$toast(makeSuccessToast("Profile updated."));
        this.next();
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast("Something went wrong. Profile not saved"));
      } finally {
        this.isSubmitting = false;
      }
    }
  },
  setup() {
    return {
      getValidationState,
    };
  },

};
</script>